<template>
  <div v-if="pinnedPostsArray.length" class="column is-12">
    <collapse title="Pinned notes">
      <loading v-if="loading" />
      <div v-else class="is-stretched">
        <div
          v-for="post in pinnedPostsArray"
          :key="post.id"
          class="note-wrapper"
        >
          <b-icon
            icon="thumbtack"
            class="pin-icon has-cursor-pointer"
            size="is-small"
            @click.native="pinPost(post)"
          />
          <note
            :timestamp="post.dateCreatedTimestamp"
            :message="post.body"
            :author-name="post.authorName"
            :is-restricted="false"
            :is-author="false"
          />
        </div>
      </div>
    </collapse>
  </div>
</template>

<script>
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where
} from "@firebase/firestore";
export default {
  name: "PinnedPosts",
  props: {
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pinnedPosts: {},
      loading: true,
      observing: {}
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    task() {
      return this.$store.getters["tasks/task"](this.taskId);
    },
    taskRef() {
      return doc(this.$firestore, "tasks", this.taskId);
    },
    pinnedPostsArray() {
      return this.$_(this.pinnedPosts)
        .map((post, index) => {
          return {
            ...post,
            ...this.mapToTaskPost(post),
            type: "post",
            index
          };
        })
        .orderBy(["dateCreated"], ["desc"])
        .value();
    },
    postsRef() {
      return query(
        collection(this.$firestore, `tasks/${this.taskId}/posts`),
        where(`isHidden`, `==`, false),
        where(`isInternal`, `==`, true)
      );
    }
  },
  async mounted() {
    this.observePinnedPosts();
    this.loading = false;
  },
  beforeDestroy() {
    this.$_.each(this.observing, unsubscribe => {
      if (this.$_.isFunction(unsubscribe)) unsubscribe();
    });
  },
  methods: {
    pinPost(post) {
      this.$store
        .dispatch("tasks/updatePost", {
          taskId: this.task._id,
          postId: post.id,
          payload: {
            isPinned: !post.isPinned
          }
        })
        .then(snapshot => {
          this.$bus.$emit("post-updated", snapshot);
          this.$toast.open({
            message: `Note ${snapshot.data().isPinned ? "pinned" : "unpinned"}`,
            position: "is-bottom",
            queue: false
          });
        });
    },
    getAuthorName(post) {
      if (!post.authorId || post.isLog || post.authorId === "system")
        return "System";
      return this.$_.get(this.authorData(post), "name");
    },
    authorData(post) {
      return this.$_.get(this.task, `participantsData.${post.authorId}`, null);
    },
    mapToTaskPost(post) {
      return {
        message: post.body,
        authorId: post.authorId,
        dateCreated: post.dateCreated.toDate(),
        dateCreatedTimestamp: post.dateCreated,
        isFile: post.isFile || false,
        fileData: post.file || null,
        fileThumbnail: post.fileThumbnail || null,
        isNote: post.isInternal || false,
        isPrivate: post.isPrivate || false,
        isPending: post.isPending || false,
        isPinned: post.isPinned || false,
        authorName: this.getAuthorName(post)
      };
    },
    observePinnedPosts() {
      return new Promise(resolve => {
        this.$set(
          this.observing,
          `pinnedPosts`,
          onSnapshot(
            query(
              this.postsRef,
              where("isPinned", "==", true),
              orderBy(`dateCreated`, `desc`)
            ),
            { includeMetadataChanges: true },
            snapshot => {
              this.$_.each(snapshot.docChanges(), change => {
                const post = change.doc;
                if (change.type === "removed") {
                  this.$delete(this.pinnedPosts, post.id);
                } else {
                  this.$set(
                    this.pinnedPosts,
                    post.id,
                    this.$_.merge({}, post.data(), { id: post.id })
                  );
                }
              });
              resolve();
            }
          )
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.note-wrapper {
  position: relative;
  & + & {
    margin-top: 0.5rem;
  }
  .pin-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  ::v-deep .message-body {
    padding-right: 2.5rem;
  }
}
</style>
